<template>
  <div class="UpgradePackage">
    <div class="filter-pane">
      <label>名称</label>
      <el-input v-model.trim="searchParams.packName" placeholder="请输入名称"></el-input>
      <label>固件版本号</label>
      <el-input v-model.trim="searchParams.packVersion" placeholder="请输入版本号"></el-input>
      <label>开始时间</label>
      <el-date-picker
        v-model="searchParams.startTime"
        type="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        placeholder="选择日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <label>结束时间</label>
      <el-date-picker
        v-model="searchParams.endTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        :picker-options="pickerOptions"
      ></el-date-picker>

      <el-button
        class="search-btn"
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="getUpgradePackageList"
      >查询</el-button>

      <div class="button-pane">
        <el-button
          type="danger"
          size="mini"
          @click="editClick"
          icon="el-icon-circle-plus-outline"
        >添加</el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-circle-close"
          @click="deleteClick"
        >删除</el-button>
      </div>
    </div>

    <div class="table-pane">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        height="94%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="packName" label="名称" width="180"></el-table-column>
        <el-table-column prop="manufacturers" label="制造商"></el-table-column>
        <el-table-column prop="packVersion" label="版本号" width="180"></el-table-column>
        <el-table-column prop="packHardwareVersion" label="硬件版本"></el-table-column>
        <el-table-column prop="packFirmwareVersion" label="固件版本"></el-table-column>
        <el-table-column prop="packSize" label="文件大小">
          <template v-slot="{row}">
            {{ row.packSize }}<span class="unit">M</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建用户"></el-table-column>
        <el-table-column prop="createDate" label="上传时间"></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{row}">
            <Iconfont
              class="iconClass"
              @click="editClick(row)"
              name="icon-bianji1"
            ></Iconfont>
            <!-- <el-button type="text" @click="deleteClick(row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :total="tablePage.total"
        :current-page="tablePage.pageNumber"
        @current-change="changePage"
        :page-size="tablePage.pageSize"
      ></el-pagination>
    </div>
    <add-modal
      :visible="addVisible"
      @input="closeDialog"
      :iProp="packageInfo"
      @on-ok="getUpgradePackageList"
    ></add-modal>
  </div>
</template>

<script>
import addModal from './components/addPackageModal.vue';
import { getTerminalUpgrade, delTerminalUpgrade } from '@/api/getHome.js';

export default {
  name: 'UpgradePackage',
  components: {
    addModal
  },
  data() {
    return {
      searchParams: { // 搜索参数
        packName: '',
        packVersion: '',
        startTime: '',
        endTime: '',
      },
      addVisible: false, // 添加弹窗状态
      packageInfo: {}, // 修改传入数据
      tableData: [],
      tablePage: { //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      selectPackages: [], // 批量选择的升级包
      pickerOptions: {
        disabledDate(time) {
          let date = new Date();
          // date.setFullYear(date.getFullYear()+2);
          // date.setDate(date.getDate()-1);
          return (time.getTime() > date.getTime());
        }
      },
    };
  },

  mounted() {
    this.getUpgradePackageList();
  },
  methods: {

    /** 获取升级包list */
    async getUpgradePackageList() {
      if (new Date(this.searchParams.startTime) > new Date(this.searchParams.endTime)) {
        this.$message({
          message: '请选择正确的开始和结束时间',
          type: 'warning'
        });
        return false;
      }
      if ((this.searchParams.startTime || this.searchParams.endTime) && (!this.searchParams.startTime || !this.searchParams.endTime)) {
        this.$message({
          message: '请选择完整的时间范围',
          type: 'warning'
        });
        return false;
      }
      let searchParams = JSON.parse(JSON.stringify(this.searchParams));
      if (searchParams.startTime) {
        searchParams.startTime = searchParams.startTime + ' 00:00:00';
        searchParams.endTime = searchParams.endTime + ' 23:59:59';
      }
      try {
        const result = await getTerminalUpgrade({
          ...searchParams,
          pageIndex: this.tablePage.pageNumber,
          pageSize: this.tablePage.pageSize
        });

        if (result.flag !== 1) return this.$message.error(result.msg);

        // this.$message.success(result.msg);
        this.tableData = result.rows;
        this.tablePage.total = result.total;

      } catch ({ msg }) {
        console.log(msg);
        this.$message.error(msg);
      }
    },

    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.getUpgradePackageList();
    },

    /** 表格勾选 */
    handleSelectionChange(val) {
      this.selectPackages = val;
    },

    // 模态框关闭
    closeDialog(model) {
      this.addVisible = model;
      this.packageInfo = {};
    },

    // 删除升级包
    deleteClick(row) {
      const { id } = row;

      if (!id && !this.selectPackages.length) return this.$message.error('请选择至少一条升级包数据');

      let params = {
        ids: id || this.selectPackages.map(p => p.id)
      };
      this.$confirm('此操作将永久删除文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await delTerminalUpgrade(params);

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.getUpgradePackageList();
        this.$message.success('删除成功');
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    // 编辑点击事件 
    editClick(row) {
      const { id } = row;
      this.packageInfo = {};
      if (id) this.packageInfo = row;
      this.addVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.UpgradePackage {
  width: 100%;
  height: 100%;
  position: relative;
}
.filter-pane {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > label {
    margin: 0 8px;
    color: #666666;
    font-size: 12px;
  }
  & > .el-input {
    width: 130px;
  }
}
.search-btn {
  margin: 0 10px;
}
.button-pane {
  position: absolute;
  right: 0;
}
.table-pane {
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 25px;
  box-shadow: 0px 0px 10px 1px rgba(66, 120, 201, 0.2);
  border-radius: 8px;
}
.el-table {
  border-radius: 8px;
}
.el-pagination {
  text-align: right;
  margin: 5px 0;
}
.iconClass {
  font-size: 24px;
  color: #4278c9;
}
::v-deep .el-table__row--striped {
  background-color: #dce8fc;
}
.unit {
  font-size: 15px;
  color: #1989fa;
}
</style>