<template>
  <Dialog
    :value="visible"
    width="540px"
    height="300px"
    okText="确定"
    :submit="submitOk"
    hiddenCancel
    @close="$emit('input',$event)"
  >
    <!-- 标题 -->
    <template #title>
      <span>编辑升级包</span>
    </template>

    <!-- 内容区-->
    <div class="mainCls">
      <el-input
        placeholder="请输入名称"
        v-model="packageParams.packName"
        :disabled="editType"
      >
        <template slot="prepend">
          <label class="required">名称</label>
        </template>
      </el-input>

      <el-input
        placeholder="请输入版本号"
        v-model="packageParams.packVersion"
        :disabled="editType"
      >
        <template slot="prepend">
          <label class="required">版本号</label>
        </template>
      </el-input>

      <el-input
        placeholder="请输入硬件版本号"
        v-model="packageParams.packHardwareVersion"
      >
        <template slot="prepend">
          <label class="required">硬件版本号</label>
        </template>
      </el-input>

      <el-input
        placeholder="请输入固件版本号"
        v-model="packageParams.packFirmwareVersion"
      >
        <template slot="prepend">
          <label class="required">固件版本号</label>
        </template>
      </el-input>

      <el-input placeholder="请输入制造商" v-model="packageParams.manufacturers">
        <template slot="prepend">
          <label class="required">制造商</label>
        </template>
      </el-input>

      <el-upload
        ref="upload"
        action="#"
        :limit="1"
        :multiple="false"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-change="handleChange"
      >
        <el-input
          clearable
          placeholder="点击上传"
          @clear="clearclick"
          v-model="fileName"
        >
          <template slot="prepend">
            <label class="required">点击上传</label>
          </template>
        </el-input>
      </el-upload>
    </div>
  </Dialog>
</template>

<script>

/**
 * 报警处理
 */

import Dialog from '@/components/Dialog/DialogBright';
import { mapState } from 'vuex';
import { addTerminalUpgrade, updateTerminalUpgrade } from '@/api/getHome.js';

export default {
  name: 'AlarmHandelDialog',
  components: {
    Dialog
  },
  props: {
    /**
     * 控制弹框
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * 传承参数
     */
    iProp: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      packageParams: this.initParams(),
      fileName: '', // 显示的文件name
      editType: false
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.editType = false;
        if (!val) return this.packageParams = this.initParams();
        const { id, packUrl } = this.iProp;
        if (!id) return;
        this.packageParams = this.iProp;
        this.editType = true;
        this.fileName = packUrl;
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 初始化数据
    initParams() {
      return {
        packName: '',
        packVersion: '',
        packHardwareVersion: '',
        packFirmwareVersion: '',
        manufacturers: '',
        packFile: null
      };
    },
    // 文件上传时触发
    handleChange(file) {
      this.fileName = file.name;
    },
    // 文件上传前的钩子
    beforeAvatarUpload(file) {
      this.packageParams.packFile = file;
    },
    //清除输入框文件
    clearclick() {
      this.fileName = '';
      this.packageParams.packFile = null;
    },
    // 关闭模态框
    close() {
      this.$emit('input', false);
    },

    //数据校验
    validateData() {
      const { packName, packVersion, packHardwareVersion, packFirmwareVersion, manufacturers } = this.packageParams;
      if (!packName) throw '请输入名称';
      if (!packVersion) throw '请输入版本号';
      if (!packHardwareVersion) throw '请输入硬件版本';
      if (!packFirmwareVersion) throw '请输入固件版本';
      if (!manufacturers) throw '请输入制造商';
    },

    // 点击确定添加
    async submitOk() {
      try {
        this.validateData();

        const { id, packFile, packUrl } = this.packageParams;


        if (!id && !packUrl) if (!packFile || packFile === null) throw '请选择文件';

        const url = id ? updateTerminalUpgrade : addTerminalUpgrade;

        const result = await url(this.packageParams);

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.$message.success(result.msg);
        this.$emit('on-ok');
        this.close();
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  .el-input {
    margin: 8px 0;
  }
}
::v-deep .el-upload {
  width: 100% !important;
}
.required:before {
  content: '* ';
  color: red;
}
</style>